import { useTranslation } from "react-i18next";
import styled from "styled-components";
import imgMaintain from "assets/images/maintain.png";
import { breakpointsMedias } from "../../configs/constants/breakpointMedias";
import logo from "assets/images/logo.png";
const MaintainPage = () => {
  const { t } = useTranslation();
  return (
    <Wrap>
      <div className="container">
        <div className="maintain-logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="maintain-img">
          <img src={imgMaintain} alt="maintain" />
        </div>
        <div className="maintain-page">
          <p className="title size-32 color-white ">{t("maintain")}</p>
          <p className="desc size-21 color-white">{t("maintainDesc")}</p>
        </div>
      </div>
    </Wrap>
  );
};

export default MaintainPage;
const Wrap = styled.div`
  text-align: center;
  min-height: 100lvh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(22, 28, 23);

  .title {
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 3rem;
    text-transform: uppercase;
  }
  .desc {
    font-weight: normal;
  }
  .maintain-img {
    margin-bottom: 40px;
    img {
      width: 100px;
    }
  }
  .maintain-logo {
    margin-bottom: 20px;
    img {
      width: 160px;
    }
  }
  ${breakpointsMedias.max1024} {
    padding: 0 16px;
    .title {
      font-size: 30px;
      margin-bottom: 10px;
    }
    .maintain-img {
      margin-bottom: 20px;
      img {
        width: 80px;
      }
    }
    .maintain-logo {
      img {
        width: 120px;
      }
    }
  }
`;
